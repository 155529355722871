<template>
  <CContainer class="min-vh-100" style="background-color: #ffffff;">
    <div v-if="loading === true">
      <div class="lds-ripple">
        <div></div>
      </div>
      <div class="row">
        <div class="col-12" style="text-align: center"> {{ this.loadingMessage }}</div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <div>
          <div class="text-center">
            <img
              alt=""
              :src="avatar"
              class="rounded-circle img-fluid"
              width="40%"
            />
          </div>

          <h3 class="text-center font-weight-normal mt-2"> {{ name }} </h3>
          <p class="text-center">
            <em class="fa fa-smile-o"></em>
            คะแนนของคุณ {{ yourpoint }} คะแนน
          </p>
          <hr />
          <CAlert v-if="this.userId === ''" color="warning">
            คุณยังไม่ได้สมัครสมาชิก กรุณาสามารถสมัครสมาชิกได้ที่เมนูสมัครสมาชิก
          </CAlert>
          <div v-if="data.length > 0">
            <CJumbotron
              color="light"
              v-for="coupon in data"
              :key="coupon.objectId"
              class="p-0 mb-3"
            >
              <router-link
                class="text-black"
                style="text-decoration:none"
                :to="`/coupon?uid=${coupon.uid}&objectId=${coupon.objectId}`">
                <table style="width:100%" aria-describedby="">
                  <th scope="col"></th>
                  <tr>
                    <td style="width:35%;">
                      <div
                        class="square-box"
                        :style="{
                          'background-image': `url('${coupon.imgUrl}'), url('${noImgUrl}')`,
                          width: '100%',
                          'background-size': 'cover',
                          'background-position': 'center center',
                        }"
                      >
                    </div>
                    </td>
                    <td style="padding:8px;padding-bottom: 4px; vertical-align:top">
                      <h5 class="font-weight-normal text-dark">{{ coupon.title }}</h5>
                      <div class="text-grey font-weight-normal" style="font-size: 12px;">
                        แลก {{ coupon.redeemFor }} คะแนน
                      </div>
                      <div class="text-grey font-weight-normal" style="font-size: 12px;">
                        รับสิทธิ์ได้ถึง {{ toEndAt(coupon.endAt) }}
                      </div>
                      <div v-if="couponBalance(coupon) === -1" class="text-grey font-weight-normal p-0 m-0" style="font-size: 12px;">
                        จำนวนไม่จำกัด
                      </div>
                      <div v-else-if="couponBalance(coupon) != null && couponBalance(coupon) >=5 " class="text-success font-weight-normal p-0 m-0" style="font-size: 12px;">
                        คงเหลือ {{ couponBalance(coupon) }} คูปอง
                      </div>
                      <div v-else-if="couponBalance(coupon) != null && couponBalance(coupon) < 5" class="text-danger font-weight-normal p-0 m-0" style="font-size: 12px;">
                        คงเหลือ {{ couponBalance(coupon) }} คูปอง
                      </div>
                    </td>
                  </tr>
                </table>
              </router-link>
            </CJumbotron>
          </div>
          <div v-else class="text-center">
            <img
              alt=""
              src="../../../../../public/img/coupon.png"
              class="img-fluid"
              width="50%"
            />
            <p class="text-black">
              ติดตามรายการคูปองจากทางร้านค้าได้เร็วๆนี้
            </p>
          </div>
        </div>
    </div>
    </div>
  </CContainer>
</template>

<script>
import moment from 'moment'
import crm from '@/services/crm'
import pos from '@/services/poscrm'
import liff from '@line/liff'
import '@/util/loader.css'
import lineutil from '@/util/line'
import util from '@/util/util'

export default {
  data() {
    return {
      data: [],
      yourpoint: 0,
      name: '',
      loading: true,
      userId: '',
      avatar: '',
      profile: {},
      //profile: JSON.parse(localStorage.getItem('profile')),
      loadingMessage: 'Loading...',
    }
  },
  computed: {
    uid() {
      return this.$route.query.uid
    },
    style() {
      return { 'font-size': '10px' }
    },
    noImgUrl() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
 created() {
    document.title = 'คูปองทั้งหมด'
    this.loading = true
    if (process.env.NODE_ENV === "development") {
      this.userId = process.env.VUE_APP_LINE_USER_ID
      console.log('developement')
      console.log('process.env.VUE_APP_LINE_USER_ID', this.userId)
      this.loadingMessage = 'Loading user....'
      this.getUser()
      this.getCoupon()
      this.loadingMessage = ''
    } else {
      liff
      .init({ liffId: process.env.VUE_APP_LIFF_ID_COUPON_LIST })
      .then(async () => {
        if (liff.isInClient()) {
          if (!liff.isLoggedIn()) {
            this.loadingMessage = 'Loging In...'
            liff.login()
          }

          const accessToken = liff.getAccessToken()

          if (accessToken) {
            this.loadingMessage = 'Loading user profile...'
            const profile = await lineutil.getProfile(accessToken)
            this.profile = profile.data
            localStorage.setItem('profile', JSON.stringify(this.profile))

            this.userId = this.profile.userId
            this.avatar = this.profile.pictureUrl

            this.loadingMessage = 'Loading user....'
            await this.getUser()
            await this.getCoupon()
            this.loadingMessage = ''
          }
        } else {
          this.loadingMessage = 'Your browser does not support.'
        }
      })
    }
  },
  methods: {
    dateRange(startAt, endAt) {
      if (startAt === '' && endAt === '') {
        return 'ไม่จำกัดระยะเวลา'
      } else if (startAt == null && endAt == null) {
        return 'ไม่จำกัดระยะเวลา'
      } else {
        let start = moment(startAt).format('DD/MM/YYYY')
        let end = moment(endAt).format('DD/MM/YYYY')
        return start + ' ถึง ' + end
      }
    },
    toEndAt(endAt) {
      if (endAt === '' || endAt == null) {
        return 'ไม่จำกัดระยะเวลา'
      } else {
        let end = moment(endAt).format('DD MMM YYYY / HH:mm')
        return end
      }
    },
    couponBalance(coupon) {
      if (coupon.couponAmount == '') {
        return null
      } else if (coupon.couponAmount === -1) {
        return -1
      } else if (coupon.couponAmount != undefined && coupon.redeemCount != undefined) {
        return coupon.couponAmount - coupon.redeemCount
      }
      return null
    },
    async getUser() {
      const uid = this.uid
      try {
        const response = await pos({method: 'get', url: '/api/v1.0/member/' + uid + '/getbyuserId/' + this.userId})
        const data = response.data || {}
        const documents = data.data.documents
        if (documents !== null) {
          if (response.data.error.code === 0) {
            this.member = response.data.data.documents

            let point = this.member.point || 0

            this.memberObjectId = this.member.objectId
            this.yourpoint = util.convertNumber(point)
            this.name = (this.member.firstname || this.member.name || + ' ') + (this.member.lastname || '')
          }
        }
      } catch (error) {
        this.loadingMessage = error
        console.log(error)
      }
    },
    async getCoupon() {
      this.loading = true
      const uid = this.uid

      try {
        const res = await crm({ method: 'get', url: '/api/v1.0/' + uid + '/coupon/get', })
        this.data = res.data.data.documents
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loadingMessage = error
      }
    },
  },
}
</script>

<style>
.square-box {
  position: relative;
  width: 20%;
  overflow: hidden;
  border-radius: 5px;
}
.square-box:before {
  content: '';
  display: block;
  padding-top: 100%;
}
</style>
